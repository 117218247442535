.chatbot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    height: 500px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .chatbot.open {
    height: 550px;
  }
  
  .chatbot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .chatbot-title {
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .chatbot-body {
    height: calc(100% - 50px); /* adjust for header height */
    padding: 10px;
    overflow-y: scroll;
  }
  
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:  sans-serif;
}
.flex-container {

  display: flex;
  background-color: #f1f1f1;
  flex-wrap: wrap; /* in mobile responsive apps, the items will wrap to next line */
  flex-basis: 33.333333%;
  flex-direction:row;
  justify-content: center;
  align-items: center; /* Vertically aligns items in center of container */
  flex-flow: row wrap;
}

.flex-container > div {
  background-color: DodgerBlue;
  color: white;
  width: 200px;
  margin: 10px;
  text-align: center;
  line-height: 200px;
  font-size: 30px;

}

.home,
.services,
.products,
.contactdetails {
  display: flex;
  height: 20vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: pink;
}

.services {
  background-image: url('/static/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  align-items: center;
  align-content: center;
}

.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
  height: 500px;
  z-index: 1000;
}
.chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 1000;

}

.chatbot.open {
  display: block;
}
.services-center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
  font-weight: bold;
  margin-top:10px;  
}
.custom-color{
  color:red;
}

.products {
  background-image: url('/static/images/img-2.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  margin-bottom: 10px;
}

.contactdetails {
  background-image: url('/static/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-description {
  padding: 20px;
  text-align: center;
}

.item-description h2 {
  margin: 0 0 10px;
}

.short-description {
  font-style: italic;
  margin: 0 0 10px;
}

.facilities {
  font-weight: bold;
  margin: 0;
}
/* ContactDetails.css */
.contact-details-container {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
}

.contact-details-list {
  list-style: none;
  margin: 0;
  display: flex;
flex-direction: column;
flex-wrap: wrap;
  padding: 0;
}

.contact-details-label {
  font-weight: bold;
  margin-right: 10px;
}

.contact-details-value {
  color: #666;
}

.blog-flex-container {
  display: flex;
  background-color: #f1f1f1;
  flex-wrap: wrap; /* in mobile responsive apps, the items will wrap to next line */
  flex-direction:column;
  justify-content:space-evenly;
  padding: 10px;
  margin: 10px;
  align-items:flex-start; /* Vertically aligns items in center of container */
  font-family: arial;
}

.chatbot-container {
  display: flex;
  justify-content: flex-end;
}

.custom-font
{
  font-family: Arial, Helvetica, sans-serif;
}
.custom-text{
  margin:5px;
}
.custom-margin{
  margin-bottom: 20px;
  margin-top: 20px;
}
ul{
  list-style-type: none;
}
.dotul{
  list-style-type:disc;
}
li{
  margin:5px;
}
li:first-child{
  list-style-type: none;
}
customli{
  list-style-type: none;
  text-align: center;
}
.h2Heading{
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
    font-family: Arial, sans-serif;
    color: #444;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  font-family: Arial, sans-serif;
  color: #444;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
th, td {
  padding: 10px;
  border: 1px solid #ddd;
}
th {
  background-color: #eee;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #f7f7f7;
}
tr:hover {
  background-color: #f5f5f5;
}
@keyframes fade-in {
  from { background-color: #f0f0f0; }
  to { background-color: #fff; }
}
tr {
  animation: fade-in 0.3s ease-in-out;
}
@keyframes highlight {
  from { background-color: #fff; }
  to { background-color: #fce4ec; }
}
tr:hover {
  animation: highlight 0.5s ease-in-out;
}